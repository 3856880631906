import $store from '../../../store';
import { $axios } from '@/services/axios';

const state ={
    img:[
        {mode:'environment',data:[]},
        {mode:'safety',data:[]},
        {mode:'society',data:[]}
    ]
}

const mutations = {
    SET_IMG_DATA(state, payload) {

        var dataImg = state.img.find(function(x) {
            return x.mode == payload.data[0].type
        });

        dataImg.data.push(...payload.data)
    },
    CLEAR(state){
        state.img = [
            {mode:'environment',data:[]},
            {mode:'safety',data:[]},
            {mode:'society',data:[]}
        ]
    },
    
}

const actions = {
    async getImgData({ commit }, payload) {
        const response = await $axios({
            method: "GET",
            url: `api/image/datatable/${payload}`,
            headers: {
                'Authorization': `Bearer ${$store.state.auth.token}`
            }
        })
        
        .then(res => {
            commit('SET_IMG_DATA', res.data)
            return res.data;
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    },

}
const getters = {}

export default {
    namespaced:true,
    state,
    mutations,
    getters,
    actions
}