import $store from '../../../store';
import { $axios } from '@/services/axios';

const state ={
    dataProd: [],
    typeProd: [],
    dt2w: [],
    dt3w: [],
    dt4w: [],
    dtother: [],
    listCategory:[],
    dtProduk:[]
}

const mutations = {
   
    SET_TYPE_PRODUK(state, typeProd) {
        state.typeProd = typeProd;
    },
    SET_DT2W(state, dt2w) {
        state.dt2w = dt2w;
    },
    SET_DT3W(state, dt3w) {
        state.dt3w = dt3w;
    },
    SET_DT4W(state, dt4w) {
        state.dt4w = dt4w;
    },
    SET_DT_OTHER(state, dtother) {
        state.dtother = dtother;
    },
    SET_LIST_CATEGORY(state, listCategory){
        state.listCategory = listCategory
    },
    SET_LIST_DTPRODUK(state, payload){
        state.dtProduk = payload
    }
}

const actions = {

    async getListCategory({ commit }) {
        
        const response = await $axios({
            method: "GET",
            url: "api/product/categories",
            headers: {
                'Authorization': `Bearer ${$store.state.auth.token}`
            }
        })
        
        .then(res => {
            commit('SET_LIST_CATEGORY', res.data)
            return res.data;
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    },

    async getCategoryBy({ commit }, data = {}, page = {page: 1, perpage: data.perpage ? data.perpage : 100}) {
        data = {...page, ...data}
        
        let url = `api/product/datatable?perpage=${data.perpage}&page=${data.page}&search[product.category_id]=${decodeURI(data.type)}`
       
        if(data.query){
            url += data.query
        }
        const response = await $axios({
            method: "GET",
            url: url,

            headers: {
                'Authorization': `Bearer ${$store.state.auth.token}`
            }
        })
        
        .then(res => {
            commit('SET_LIST_DTPRODUK', res.data)
            return res.data;
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    },
    
    async getProdType({ commit }) {
        
        const response = await $axios({
            method: "GET",
            url: "api/product-type/datatable",
            headers: {
                'Authorization': `Bearer ${$store.state.auth.token}`
            }
        })
        
        .then(res => {
            commit('SET_TYPE_PRODUK', res.data)
            return res.data;
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    },

    async getDtProd({ commit }, data = {}, page = {page: 1, perpage: data.perpage ? data.perpage : 20}) {
        data = {...page, ...data}
        let url = `api/product/datatable?perpage=${data.perpage}&page=${data.page}&search[product.category_id]=${data.type}`
        if(data.query){
            url += data.query
        }
        
        const response = await $axios({
            method: "GET",
            url: url,
            headers: {
                'Authorization': `Bearer ${$store.state.auth.token}`
            }
        })
        
        .then(res => {
            commit('SET_LIST_DTPRODUK', res.data)

            return res.data;
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    },

}
const getters = {
    
    withFilter: (state) => (category, type) => {

        const data =  state.dataProd.data.filter((item) => {
            return item.category == category  && item.type == type 
        })

        return data
    }
         
}

export default {
    namespaced:true,
    state,
    mutations,
    getters,
    actions
}