import auth from './auth';
import home from './home';
import about from './about';
import csr from './csr';
import news from './news';
import produk from './produk';
import karir from './karir';
import image from './image';

export default {
    auth,
    home,
    about,
    csr,
    news,
    produk,
    karir,
    image
}