import $store from '../../../store';
import { $axios } from '@/services/axios';

const state ={
    allImg: []
}

const mutations = {
   
    SET_allImg(state, allImg) {
        state.allImg = allImg;
    }
}

const actions = {
    
    
    async getAllImg({ commit }, data = {}, page = {page: 1, perpage: 200}) {
        data = {...page, ...data}
        let url = `api/image/datatable?perpage=${data.perpage}&page=${data.page}`

        if(data.query){
            url += data.query
        }
        
        const response = await $axios({
            method: "GET",
            url: url,
            headers: {
                'Authorization': `Bearer ${$store.state.auth.token}`
            }
        })
        
        .then(res => {
           
            commit('SET_allImg', res.data)
    
            return res.data;
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    },

}
const getters = {
    
    imgWtFilter: (state) => (type) => {

        if(!state.allImg.data) return 

        const data =  state.allImg.data.filter((item) => {
            return item.type == type
        })

        return data
    }
         
}

export default {
    namespaced:true,
    state,
    mutations,
    getters,
    actions
}