import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./routes";
import { setPageTitleMiddleware } from './middlewares'

Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	linkActiveClass: 'active',
    linkExactActiveClass: 'active',
});

router.beforeEach(setPageTitleMiddleware)
export default router;


