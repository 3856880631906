const state ={
    token: '71063a7eab248181eafe665bf4ae346f-sFqsGThLIgEL9Kvjo0NZInsTou2NMoexYTnPwVjXAgV1K2scDuW58jBBy53'
}

const mutations = {
    
}

const actions = {}
const getters = {}

export default {
    namespaced:true,
    state,
    mutations,
    getters,
    actions
}