<template>
  
    <div>
        <transition name="fade">
    
            <div class="fixed bglac inset-0 zndex w-full min-h-full grid place-items-center" 
                v-if="modal"
                @click="onToggle"
               
            >
                
                <div class="md:w-4/6 w-full shadow-lg bg-white my-3 relative rounded-lg overflow-hidden">
                
                    <div class=" text-gray-600">

                        <div 
                            class="h-96 w-full  bg-contain bg-no-repeat bg-center mt-4 " :style="{ backgroundImage: `url(${notice[0].image})` }">
                        </div>
                        <div class="text-sm py-4 px-4 vtml" v-html="notice[0].note" v-if="lang == 'id'">
                            
                        </div>
                        <div class="text-sm py-4 px-4 vtml" v-html="notice[0].note_eng" v-else>
                            
                        </div>
                       
                    </div>

                    <div class="px-5 pb-4 flex justify-end">
                        <button class="text-sm py-2 px-3 text-white bg-red-500 hover:bg-red-600 rounded  transition duration-150">Close</button>
                	</div>
                
                    
                </div>
                
            </div>

        </transition>

    </div>
   
</template>

<script>
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState({
            modal: state => state.home.modal ? state.home.modal :false,
            notice: state => state.home.notice ? state.home.notice :'',
            lang: state => state.home.lang ? state.home.lang :'id'
        })
       
    },
    methods: {
        onToggle() {
            this.$store.commit('home/SET_MODAL', false)
        },

    },
    
};
</script>

<style lang="scss">
    .fade-enter {
        opacity: 0;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 300ms ease-out;
    }

    .fade-leave-to {
        opacity: 0;
    }

    .bglac{
        background-color:rgba(0, 0, 0, 0.597);
    }

    .zndex{
        z-index: 9999;
    }

    .vtml{
        p{
            @apply mt-1 text-justify;
        }

        p:nth-child(1){
            @apply text-center mb-5
        }

        
    }
</style>