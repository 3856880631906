import $store from '../../../store';
import { $axios } from '@/services/axios';

const state ={
    article: [],
    viewArt: {}
}

const mutations = {
    SET_ARTICLE_DATA(state, article) {
        state.article = article;
    },
    SET_VIEW_ART(state, viewArt) {
        state.viewArt = viewArt;
    }
}

const actions = {
    async getArtData({ commit }, data = {}, page = {page: 1, perpage: 10}) {
        data = {...page, ...data}
        let url = `api/article/datatable?perpage=${data.perpage}&page=${data.page}`

        if(data.query){
            url += data.query
        }

        const response = await $axios({
            method: "GET",
            url: url,
            headers: {
                'Authorization': `Bearer ${$store.state.auth.token}`
            }
        })
        
        .then(res => {
            commit('SET_ARTICLE_DATA', res.data)
            return res.data;
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    },


    async getViewArt({ commit }, payload) {
        let url = `api/article/${payload.hash}`
        const response = await $axios({
            method: "GET",
            url: url,
            headers: {
                'Authorization': `Bearer ${$store.state.auth.token}`
            }
        })
        
        .then(res => {
            commit('SET_VIEW_ART', res.data)
            return res.data;
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    }

}
const getters = {}

export default {
    namespaced:true,
    state,
    mutations,
    getters,
    actions
}