<template>
  <div class="relative clear-both font-pop">
    <div class="flex flex-col px-2 py-12 lg:px-32 bg-secondary font-pop md:flex-row">
        <div class="w-6/12 text-left">
            <div>
                <h5 class="text-sm font-semibold text-white">{{ $t('footer.info') }}</h5>
                <h4 class="my-2 text-base font-semibold text-white">PT DHARMA POLIMETAL Tbk</h4>
            </div>
            <div>
                <p class="text-sm text-white">
                    Head Office :<br>
                    Jl. Angsana Raya Blok A9 No.8 Delta Silicon 1 Cikarang<br>
                    17550<br>
                    Telp. (6221) 897 4637 / (6221) 897 4559<br>
                    Fax : (6221) 899 01656
                </p>
            </div>
            <div class="mt-8">
                <span class="flex items-center px-4 py-2 text-sm font-semibold text-white cursor-pointer w-max bg-primary" @click="routeOurBusiness">
                    {{ $t('footer.more') }}
                    <img src="@/assets/img/right-arrow-white.svg" alt="" class="w-4 ml-2">
                </span>
            </div>
        </div>
        <div class="mt-6 text-left">
            <h3 class="font-semibold text-white font-base">{{ $t('footer.foll') }}</h3>
            <div class="flex my-4">
                <a :href="data.instagram" target="_blank"><img src="@/assets/img/iconmonstr-instagram-14.svg" alt="iconmonstr-instagram-14.svg" class="w-8 h-8 mr-2 cursor-pointer"></a>
                <a :href="data.facebook" target="_blank"><img src="@/assets/img/fb-icon.svg" alt="fb icon" class="w-8 h-8 cursor-pointer"></a>
                <a :href="data.twitter" target="_blank"><img src="@/assets/img/twitter-icon.svg" alt="twitter icon" class="w-8 h-8 mx-2 cursor-pointer"></a>
                <a :href="data.youtube" target="_blank"><img src="@/assets/img/youtube-icon.svg" alt="youtube icon" class="w-8 h-8 cursor-pointer"></a>
            </div>
            <h3 class="mb-2 font-semibold text-white font-base">{{ $t('footer.contas') }}</h3>
            <router-link
                to="/kontak"
                @click.native="scrollToTop"
            >
                <span class="flex items-center px-4 py-2 text-sm font-semibold text-white cursor-pointer w-max bg-links">
                    {{ $t('footer.contas') }}
                    <img src="@/assets/img/right-arrow-white.svg" alt="" class="w-4 ml-2">
                </span>
            </router-link>
        </div>
    </div>
    <div class="py-4 text-sm text-center text-white bg-black lg:px-32 lg:text-left">
        <h5>Copyright © 2024 PT Dharma Polimetal Tbk. All Rights Reserved.</h5>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    methods: { 
        scrollToTop() {
            window.scrollTo(0,0);
        },
        routeOurBusiness() {
            this.$router.push({
				name: 'Bisniskami'
			});
        }
    },
    computed: {
        ...mapState({
            data: state => state.home.home ? state.home.home.data :''
        }),
    }
}
</script>

<style>

</style>