import { DOMAIN_TITLE } from '../.env';

export const routes = [
    {
        path: '/',
        redirect: '/beranda'
    },
    {
        path: "/beranda",
		name: "Home",
		component: () => import('@/views/Home'),
		meta: { title: `${DOMAIN_TITLE}` }
    },
    {
		path: "/tentangkami",
		name: "About",
		component: () => import('@/views/About'),
		meta: { title: `${DOMAIN_TITLE} | Tentang Kami` },
	},
    {
        path: "/bisniskami",
        name: "Bisniskami",
        component: () => import("@/views/BisnisKami.vue"),
        meta: { title: `${DOMAIN_TITLE} | Bisnis Kami` },
    },
    {
        path: "/tatakelolaperusahaan",
        name: "Tatakelola",
        component: () => import("@/views/TataKelolaPerusahaan.vue"),
        meta: { title: `${DOMAIN_TITLE} | Tata Kelola Perusahaan` },
    },
    {
        path: "/produk",
        name: "Produk",
        component: () => import("@/views/Produk.vue"),
        meta: { title: `${DOMAIN_TITLE} | Produk` },
    },
    {
        path: "/csr",
        name: "CSR",
        component: () => import("@/views/csr.vue"),
        meta: { title: `${DOMAIN_TITLE} | CSR` },
    },
    {
        path: "/hubunganinvestor",
        name: "HubunganInvestor",
        component: () => import("@/views/HubunganInvestor.vue"),
        meta: { title: `${DOMAIN_TITLE} | Hubungan Investor` },
    },
    {
        path: "/berita",
        component: () => import("@/views/News.vue"),
       
        children: [
            {
                name: "Berita",
                path: "",
                component: () => import("@/views/news/index.vue"),
                meta: { title: `${DOMAIN_TITLE} | Berita` },
            },
            {
                name: "detail",
                path: ":id",
                component: () => import("@/views/news/view.vue"),
                meta: { title: `${DOMAIN_TITLE} | Artikel` },
            },
        ]
    },
    {
        path: "/karir",
        component: () => import("@/views/Karir.vue"),
        children: [
            {
                name: "Karir",
                path: "",
                component: () => import("@/views/karir/loker.vue"),
                meta: { title: `${DOMAIN_TITLE} | Karir` },
            },
            {
                name: "List",
                path: "list/:id",
                component: () => import("@/views/karir/loker/loker_list.vue"),
                meta: { title: `${DOMAIN_TITLE} | List Karir` },
            },
            {
                name: "View",
                path: "view",
                component: () => import("@/views/karir/loker/loker_view.vue"),
                meta: { title: `${DOMAIN_TITLE} | Detail Lowongan` },
            },
            {
                name: "Apply",
                path: "apply",
                component: () => import("@/views/karir/loker/loker_apply.vue"),
                meta: { title: `${DOMAIN_TITLE} | Apply Lowongan` },
            },
            {
                name: "SDM",
                path: "sdm",
                component: () => import("@/views/karir/sdm.vue"),
                meta: { title: `${DOMAIN_TITLE} | SDM` },
            },
            {
                name: "Bersama",
                path: "bersama",
                component: () => import("@/views/karir/bersama.vue"),
                meta: { title: `${DOMAIN_TITLE} | Bersama` },
            },
        ]
    },
    {
        path: "/kontak",
        name: "Kontak",
        component: () => import("@/views/Kontak.vue"),
        meta: { title: `${DOMAIN_TITLE} | Kontak` },
    },

    {   
        path: "*", 
        component: () => import('@/views/404Page')
    },
]