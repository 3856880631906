import $store from '../../../store';
import { $axios } from '@/services/axios';

const state ={
    company :[],
    listLoker:[],
    viewLoker:{},
    selectCompanyId:''
}

const mutations = {
    SET_COMPANY(state, company){
        state.company = company
    },
    SET_LIST_LOKER(state, listLoker){
        state.listLoker = listLoker
    },
    SET_VIEW_LOKER(state, viewLoker){
        state.viewLoker = viewLoker
    },
    SET_COMPANY_ID(state, selectCompanyId){
        state.selectCompanyId = selectCompanyId
    }
}

const actions = {
 
    async getCompany({ commit }, data = {}, page = {page: 1, perpage: 30}) {
        data = {...page, ...data}
        let url = `api/company/datatable?perpage=${data.perpage}&page=${data.page}`

        if(data.query){
            url += data.query
        }
        
        const response = await $axios({
            method: "GET",
            url: url,
            headers: {
                'Authorization': `Bearer ${$store.state.auth.token}`
            }
        })
        
        .then(res => {
            commit('SET_COMPANY', res.data)

            return res.data;
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    },

    async getListLoker({ commit }, data = {}, page = {page: 1, perpage: 100}) {
        data = {...page, ...data}
        let url = `api/career/datatable?perpage=${data.perpage}&page=${data.page}`

        if(data.query){
            url += data.query
        }
        
        const response = await $axios({
            method: "GET",
            url: url,
            headers: {
                'Authorization': `Bearer ${$store.state.auth.token}`
            }
        })
        
        .then(res => {
            commit('SET_LIST_LOKER', res.data)

            return res.data;
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    },

    async getViewLoker({ commit }, id) {

        const response = await $axios({
            method: "GET",
            url: `api/career/${id}`,
            headers: {
                'Authorization': `Bearer ${$store.state.auth.token}`
            }
        })
        
        .then(res => {
            commit('SET_VIEW_LOKER', res.data)
            return res.data;
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    }

}
const getters = {}

export default {
    namespaced:true,
    state,
    mutations,
    getters,
    actions
}