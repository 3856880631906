<template>
    <div class="py-4 lg:px-32 px-2 flex items-center justify-between fixed top-0 bg-grays-50 z-50 w-full">
        <div class="w w-40">
            <img src="@/assets/img/logo.png" alt="company logo" class="w-full">
        </div>
        <div class="z-20 lg:z-0">
                <ul class="hidden lg:flex justify-between">
                    <li 
                        v-for="(data, index) in navMenu"
                        :key="index"
                        class="nav--text ml-0 px-2 font-pop uppercase font-semibold hover:text-primary duration-100 cursor-pointer list-none"
                    >
                        <router-link
                            :to="{ name: data.route }"
                            @click.native="scrollToTop"
                        >
                            <div v-if="lang == 'id'">
                                {{ data.name }}
                            </div>
                            <div v-else>
                                {{ data.name_eng }}
                            </div>
                        </router-link>
                    </li>
                    <div class="relative">
                        <div class="w-7 h-7 shadow-lg -mt-2 ml-2 rounded-full overflow-hidden grid place-items-center cursor-pointer" @click="showFlag = !showFlag">
                            <flag :iso="ActiveFlag" v-bind:squared=false class="bg-cover bg-center h-full w-full" style="line-height:none"/>
                        </div>

                        <transition name="fade" >
                            <div v-show="showFlag" class="shadow-lg rounded-sm absolute top-7 right-0 bg-grays-50 w-36 p-4 text-left select-none" >
                                <div class="text-xs text-grays-300 mb-4">{{ $t('lang.title') }} :</div>
                                <div class="flex mt-3 items-center cursor-pointer" 
                                    v-for="(n, i) in languages" :key="i"
                                    :class="{ active: i === activeItem , hide: n.flag === ActiveFlag}"
                                    @click="handLang(i, n.flag)"
                                >
                                    <div class="w-5 h-5 shadow-lg  rounded-full overflow-hidden grid place-items-center ">
                                        <flag :iso="n.flag" v-bind:squared=false class="bg-cover bg-center h-full w-full" style="line-height:none"/>
                                    </div>
                                    <div class="text-xs ml-3 text-gray-600">{{n.title}} </div> 
                                </div>
                            </div>
                        </transition>
                    </div>
                    
                </ul>
                <div class="lg:hidden flex flex-col" v-if="menuStatus == false" @click="menuStatus = !menuStatus">
                    <span class="h-1 w-8 bg-gray-800"></span>
                    <span class="my-1 h-1 w-8 bg-gray-800"></span>
                    <span class="h-1 w-8 bg-gray-800"></span>
                </div>
                <div class="lg:hidden relative flex flex-col" v-else @click="menuStatus = !menuStatus">
                    <span class="cross__left absolute right-0"></span>
                    <span class="cross__right absolute right-0"></span>
                </div>
        </div>
        <transition name="fade">
            <nav class="w-full h-screen py-16 bg-white z-10 absolute top-0 left-0" v-if="menuStatus">
                <ul>
                    <li 
                        v-for="(data, index) in navMenu"
                        :key="index"
                        @click="menuStatus = false"
                        class="py-2 font-pop uppercase text-sm font-semibold hover:text-primary duration-100 cursor-pointer list-none">
                        <router-link v-if="lang == 'id'" :to="{ name: data.route }">{{ data.name }}</router-link>
                        <router-link v-else :to="{ name: data.route }">{{ data.name_eng }}</router-link>
                    </li>
                </ul>
                <div class="relative grid place-items-center mt-3">
                        <div class="w-7 h-7 shadow-lg -mt-2 ml-2 rounded-full overflow-hidden grid place-items-center cursor-pointer" @click="showFlag = !showFlag">
                            <flag :iso="ActiveFlag" v-bind:squared=false class="bg-cover bg-center h-full w-full" style="line-height:none"/>
                        </div>

                        <transition name="fade" >
                            <div v-show="showFlag" class="shadow-lg rounded-sm absolute top-7 bg-grays-50 w-36 p-4 text-left select-none" >
                                <div class="text-xs text-grays-300 mb-4">Languages  :</div>
                                <div class="flex mt-3 items-center cursor-pointer" 
                                    v-for="(n, i) in languages" :key="i"
                                    :class="{ active: i === activeItem , hide: n.flag === ActiveFlag}"
                                    @click="handLang(i, n.flag)"
                                >
                                    <div class="w-5 h-5 shadow-lg  rounded-full overflow-hidden grid place-items-center ">
                                        <flag :iso="n.flag" v-bind:squared=false class="bg-cover bg-center h-full w-full" style="line-height:none"/>
                                    </div>
                                    <div class="text-xs ml-3 text-gray-600">{{n.title}} </div> 
                                </div>
                            </div>
                        </transition>
                    </div>
            </nav>
            
        </transition>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            menuStatus: false,
            navMenu: [
                {
                    name: 'Beranda',
                    name_eng: 'Home',
                    route: 'Home'
                },
                {
                    name: 'Tentang Kami',
                    name_eng: 'About Us',
                    route: 'About'
                },
                {
                    name: 'Bisnis Kami',
                    name_eng: 'Our Business',
                    route: 'Bisniskami'
                },
                {
                    name: 'Produk',
                    name_eng: 'Product',
                    route: 'Produk'
                },
                {
                    name: 'CSR',
                    name_eng: 'CSR',
                    route: 'CSR'
                },
                {
                    name: 'Tata Kelola Perusahaan',
                    name_eng: 'Corporate Governance',
                    route: 'Tatakelola'
                },
                {
                    name: 'Hub Investor',
                    name_eng: 'Investor Relation',
                    route: 'HubunganInvestor'
                },
                {
                    name: 'Berita',
                    name_eng: 'News',
                    route: 'Berita'
                },
                {
                    name: 'Karir',
                    name_eng: 'Career',
                    route: 'Karir'
                }
            ],
            languages: [
					{ flag: 'gb', language: 'en', title: 'english' },
					{ flag: 'id', language: 'id', title: 'indonesia' }
				],
            showFlag : false,
            activeItem: null,
            ActiveFlag: ''
        }
    },
    mounted() {
        this.ActiveFlag = this.lang
    },
    computed: {
        ...mapState({
            lang: state => state.home.lang ? state.home.lang :'id'
        }),
    },
    methods: {
        handLang(i, flag){
            this.activeItem = i;
            this.ActiveFlag = flag
            this.showFlag = false
            this.$store.commit('home/SET_LANG', flag)

            // setTimeout(() => {
            //     window.location.reload(true)
            // }, 200);
        },
        scrollToTop() {
            window.scrollTo(0,0);
        }
    }
    
}
</script>

<style>
    @media (min-width: 1000px) {
        .nav--text {
            font-size: 0.75rem;
        }   
    }
    @media (min-width: 1356px) {
        .nav--text {
            font-size: 0.875rem;
        }   
    }
    .active{
        @apply text-primary;
    }
    .cross__left, .cross__right {
        @apply h-1 w-8 bg-gray-800;
    }
    .cross__left {
        transform: rotate(45deg);
    }
    .cross__right {
        transform: rotate(-45deg);
    }
</style>