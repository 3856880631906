<template>
  <div id="app">
    <Modal />
    <NavMenu />
    <router-view />
    <NavBottom />
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import NavMenu from "@/components/NavMenu";
import NavBottom from "@/components/NavBottom";

export default {
  components: {
    Modal,
    NavMenu,
    NavBottom,
  },
  mounted() {
    this.getAllData();

    // setTimeout(() => {
    // 	this.getStock()
    // 	console.log('hale')
    // }, 300000);
  },
  methods: {
    async getAllData() {
      try {
        await this.$store.dispatch("home/getNotice");
        await this.$store.dispatch("home/getAllData");
        await this.$store.dispatch("home/getCSR");

        this.getArtData();
        await this.$store.dispatch("produk/getProdType");
        await this.$store.dispatch("produk/getListCategory");
        const payload= {type: 1 , perpage:  20}
        await this.$store.dispatch("produk/getCategoryBy", payload);
        //  await this.$store.dispatch('home/getSTOCK')
      } catch (error) {
        console.log(error);
      }
    },

    //GETT ALL NEWS
    async getArtData() {
      try {
        const query = {
          query: `&sort[field]=created_at&sort[dir]=desc`,
        };
        await this.$store.dispatch("news/getArtData", query);
        await this.$store.dispatch("image/getAllImg");
      } catch (error) {
        console.log(error);
      }
    },

    async getStock() {
      try {
        await this.$store.dispatch("home/getSTOCK");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.home,
.about,
.ourbusiness,
.produkKami,
.news,
.tatakelola,
.hubinvest {
  padding-top: 56px;
}
ul li {
  margin-left: 15px;
  list-style-type: disc;
}
.material-pagination li {
  list-style-type: none;
  margin-left: 0;
}
</style>
