import axios from 'axios';

export const $axios = axios.create({
    baseURL: process.env.VUE_APP_DRMA_API
});

export default {
    /**
     *
     * @param {import("vue/types/umd").VueConstructor} Vue
     */
    install(Vue) {

        //Intercept request
        $axios.interceptors.request.use( function(config) {
            document.querySelector('.lds-ring').classList.add('show');
            return config;
        }, function(error) {
            document.querySelector('.lds-ring').classList.remove('show');
            return Promise.reject(error)
        });

        //Intercept response
        $axios.interceptors.response.use( function(response) {
            document.querySelector('.lds-ring').classList.remove('show');

            return response;
        }, function(error) {
            document.querySelector('.lds-ring').classList.remove('show');

            return Promise.reject(error);
        })

        Vue.prototype.$axios = $axios;
    }
}
