import $store from '../../../store';
import { $axios } from '@/services/axios';

const state ={
    home: {},
    lang: 'id',
    tab: '',
    modal: false,
    notice:'',
    csr:{},
    stock:null
}

const mutations = {
    SET_HOME_DATA(state, home) {
        state.home = home;
    },
    SET_LANG(state, payload){
        state.lang = payload
    },
    SET_TAB(state, payload){
        state.tab = payload
    },
    SET_MODAL(state, payload){
        state.modal = payload
    },
    SET_NOTICE(state, payload){
        state.notice = payload
    },
    SET_CSR(state, payload){
        state.csr = payload
    },
    SET_STOCK(state, payload){
        state.stock = payload
    }
}

const actions = {
    async getAllData({ commit }, payload) {
        const response = await $axios({
            method: "GET",
            url: 'api/about',
            headers: {
                'Authorization': `Bearer ${$store.state.auth.token}`
            }
        })
        
        .then(res => {
            commit('SET_HOME_DATA', res.data)
            return res.data;
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    },

    async getCSR({ commit }, payload) {
        const response = await $axios({
            method: "GET",
            url: 'api/csr/year',
            headers: {
                'Authorization': `Bearer ${$store.state.auth.token}`
            }
        })
        
        .then(res => {
            commit('SET_CSR', res.data)
            return res.data;
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    },

    async getNotice({ commit }, payload) {
        const response = await $axios({
            method: "GET",
            url: 'api/notice/all',
            headers: {
                'Authorization': `Bearer ${$store.state.auth.token}`
            }
        })
        
        .then(res => {
            commit('SET_NOTICE', res.data.data)
            return res.data;
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    },
    
    async getSTOCK({ commit }) {
        const response = await $axios({
            method: "GET",
            url: 'https://api.goapi.io/stock/idx/prices?symbols=DRMA',
            headers: {
                'X-API-KEY': process.env.VUE_APP_DRMA_KEY_API
            }
        })
        
        .then(res => {
            commit('SET_STOCK', res.data.data)
            return res.data;
            
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    },

}
const getters = {}

export default {
    namespaced:true,
    state,
    mutations,
    getters,
    actions
}