import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from './plugins/i18n';
import FlagIcon from 'vue-flag-icon';
import VueProgressiveImage from 'vue-progressive-image';
import "./style/app.css";


Vue.use(VueProgressiveImage, {
    delay: 100 // 2 seconds before the image is displayed
})
Vue.use(FlagIcon);
Vue.config.productionTip = false;


new Vue({
    router,
    store,
	i18n,
    render: h => h(App)
}).$mount('#app');
