import $store from '../../../store';
import { $axios } from '@/services/axios';

const state ={
    img:[
        {mode:'history',data:[]},
        {mode:'vimi',data:[]},
        {mode:'corporate',data:[]},
        {mode:'organization',data:[]},
        {mode:'awards',data:[]},
        {mode:'journey',data:[]},
        {mode:'rupst',data:[]}
    ],
    awards:[],
    awardYear:[]
}

const mutations = {
    SET_IMG_DATA(state, payload) {

        var dataImg = state.img.find(function(x) {
            return x.mode == payload.data[0].type
        });

        dataImg.data.push(...payload.data)
    },
    CLEAR(state){
        state.img = [
            {mode:'history',data:[]},
            {mode:'vimi',data:[]},
            {mode:'corporate',data:[]},
            {mode:'organization',data:[]},
            {mode:'awards',data:[]},
            {mode:'journey',data:[]},
            {mode:'rupst',data:[]}
        ]
    },

    SET_AWARDS(state, payload){
        state.awards = payload;
    },
    SET_AWARDS_YEARS(state, payload){
        state.awardYear = payload;
    }
    
}

const actions = {
    async getImgData({ commit }, payload) {
        const response = await $axios({
            method: "GET",
            url: `api/image/datatable/${payload}`,
            headers: {
                'Authorization': `Bearer ${$store.state.auth.token}`
            }
        })
        
        .then(res => {
            commit('SET_IMG_DATA', res.data)
            return res.data;
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    },

    async getAwards({ commit }, data = {}, page = {page: 1, perpage: 200}) {
        data = {...page, ...data}
        let url = `api/awards/datatable?perpage=${data.perpage}&page=${data.page}`

        if(data.query){
            url += data.query
        }
        const response = await $axios({
            method: "GET",
            url: url,
            headers: {
                'Authorization': `Bearer ${$store.state.auth.token}`
            }
        })
        
        .then(res => {
            commit('SET_AWARDS', res.data)
            return res.data;
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    },

    async getAwardsYears({ commit }, data = {}, page = {page: 1, perpage: 200}) {
        data = {...page, ...data}
        let url = `api/awards/datatable?perpage=${data.perpage}&page=${data.page}`

        if(data.query){
            url += data.query
        }
        const response = await $axios({
            method: "GET",
            url: url,
            headers: {
                'Authorization': `Bearer ${$store.state.auth.token}`
            }
        })
        
        .then(res => {
            const dtCard = res.data

            const dtyear = []
            const data =  dtCard.data.map((item) => {
                return item.date
            })

            dtyear.push(...data)
            
            let dtyears = dtyear.filter( function( item, index, inputArray ) {
                return inputArray.indexOf(item) == index;
            });

            let test = dtyears.filter(n => n).sort((a, b) => a < b ? -1 : 1);
             
            commit('SET_AWARDS_YEARS', test)
            return res.data;
        }).catch(err => {
            console.log(err)
            return null
        });
        return response;
    }
        

}
const getters = {}

export default {
    namespaced:true,
    state,
    mutations,
    getters,
    actions
}