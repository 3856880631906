import Vue from 'vue';
import VueI18n from 'vue-i18n';
import $store from '../store';

Vue.use(VueI18n);


const messages = {
	'gb': {
      about :{
         history :{
            our : 'Our History',
         },
         visi :{
            title1 : 'Our Vision and Mission'
         },
         corporate:{
            cop: 'Our Corporate Structure',
            succes:'Success Built In to Our Strong Business Model'
         },
         organization:{
            structure: 'Organization Structure'
         },
         awards:{
            for: "Company's Awards"
         },
         journey:{
            title: "Company's Journey"
         }
      },
      csr :{
         environment: {
            title1: 'Corporate Social Environment',
            title2: 'Bakti Dharma Polimetal Tbk Foundation'
         },
         safety: {
            title1: 'Corporate Social Safety',
            title2: 'Employment, Health and Safety'
         },
         society: {
            title1: 'Corporate Social Society',
            title2: 'Social and Community Development'
         },
         sunsb: {
            title1: 'Environmental Sustainability'
         }
      },
      news :{
         title1:'Latest News',
         see:'see more news'
      },
      karir:{
         title1: 'Opportunity to Improve Your Career',
         avalible: 'Position Available',
         our: 'Our Vacancies',
         loc:'Work location',
         years:'Years of experience',
         status:'Status',
         close:'Closing date',
         av:'Available Position',
         ot:'Other Details',
         apply:'Apply'
      },
      footer:{
         more:'see more contact',
         info:'Contact Info',
         foll:'Follow us on',
         contas:'Contact Us'
      },
      product:{
         title:'Our Products',
         title2:'Produced by'
      },
      lang:{
         title:'Language'
      }
   },

   'id': {
      about :{
         history :{
            our : 'Sejarah Kami'
         },
         visi :{
            title1 : 'Visi Misi Kami'
         },
         corporate:{
            cop: 'Struktur Perusahaan Kami',
            succes:'Sukses Dibangun Dalam Model Bisnis Kami Yang Kuat'
         },
         organization:{
            structure: 'Struktur Organisasi Kami'
         },
         awards:{
            for: 'Penghargaan untuk Perusahaan'
         },
         journey:{
            title: 'Perjalanan Perusahaan'
         }
      },
      csr :{
         environment: {
            title1: 'Lingkungan Sosial Perusahaan',
            title2: 'Yayasan Bakti Dharma Polimetal Tbk'
         },
         safety: {
            title1: 'Tanggung Jawab Sosial Perusahaan',
            title2: 'Ketenagakerjaan, Kesehatan dan Keselamatan Kerja'
         },
         society: {
            title1: 'Tanggung Jawab Sosial Masyarakat',
            title2: 'Pengembangan Sosial Dan Kemasyarakatan'
         },
         sunsb: {
            title1: 'Tanggung Jawab Terhadap Pelanggan'
         }
      },
      news :{
         title1:'Berita Terbaru',
         see:'Selengkapnya'
      },
      karir:{
         title1: 'Kesempatan untuk Meningkatkan Karir Anda',
         avalible: 'Posisi Tesedia',
         our: 'Lowongan Pekerjaan',
         loc:'Lokasi Pekerjaan',
         years:'Pengalaman',
         status:'Status',
         close:'Batas Waktu',
         av:'Posisi Tersedia',
         ot:'Detail lainnya',
         apply:'Kirim'
      },
      footer:{
         more:'Selengkapnya',
         info:'Informasi Kontak',
         foll:'Ikuti Perkembangan kami di',
         contas:'Kontak Kami'
      },
      product:{
         title:'Produk - Produk Kami',
         title2:'Diproduksi oleh'
      },
      lang:{
         title:'Bahasa'
      }
   }
};

const i18n = new VueI18n({
   locale: $store.state.home.lang,
   messages, // set locale messages
});



export default i18n;